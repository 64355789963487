import axios from 'axios';
import Swal from 'sweetalert2';
const baseUrl  = process.env.REACT_APP_BASE_URL;
const bankUrl  = process.env.REACT_APP_BANK_URL;

const wayagramUrl = `${baseUrl}/wayagram-service`;
const authUrl = `${baseUrl}/auth-service`;
const bankAuthUrl = `${bankUrl}/auth-service`;
const smAuthUrl = `${baseUrl}/auth-sm`;
const kycUrl = `${baseUrl}/kyc-servicev2`;
const bvnUrl = `${baseUrl}/bvn-service`;
const cardUrl = `${baseUrl}/card-service`;
const contactUrl = `${baseUrl}/contact-service`;
const billPaymentUrl = `${baseUrl}/billspayment-service`;
const billPaymentUrl2 = `${baseUrl}/billspayment-servicev2`;
const walletUrl = `${baseUrl}/temporal-service`;
const smWalletUrl = `${baseUrl}/temporal-sm-service`;
const paymentRequestUrl = `${baseUrl}/payment-request`;
const accountCreationUrl = `${baseUrl}/account-service`;
const roleUrl = `${baseUrl}/role-service`;
const withdrawalUrl = `${baseUrl}/withdrawal-service`;
const ussdUrl = `${baseUrl}/ussd-service`;
const disputeUrl = `${baseUrl}/social-dispute`;
const socketUrl = `${baseUrl}/chats-service`;
const logUrl = `${baseUrl}/logs-service`;
const wayapayDispute = `${baseUrl}/complaint-service`;
const contentManagementUrl = `${baseUrl}/content-management-service`;
const notificationUrl = `${baseUrl}/notification-service`;
const notificationAggUrl = `${baseUrl}/notification-aggregate`;
const fileResourseUrl = `${baseUrl}/file-resource-service`;
const ussdTopUp = `${baseUrl}/ussd-middle-ware`;
const settlementUrl = `${baseUrl}/settlement-service`;
const commissionUrl = `${baseUrl}/commission-service`;
const qrCodeUrl = `${baseUrl}/qrcode-service`;
const referralUrl = `${baseUrl}/referral-service`;
const walletV2 = `${baseUrl}/wallet-v2`;
const nipBvnUrl = `${baseUrl}/nip-bvn-service`;
const fraudUrl = `${baseUrl}/fraud-service`
const statementUrl = `${baseUrl}/statement-service`


export const customBaseUrl = {
  statementUrl,
  wayagramUrl,
  authUrl,
  kycUrl,
  bvnUrl,
  cardUrl,
  contactUrl,
  billPaymentUrl,
  billPaymentUrl2,
  ussdUrl,
  walletUrl,
  smWalletUrl,
  paymentRequestUrl,
  accountCreationUrl,
  roleUrl,
  withdrawalUrl,
  disputeUrl,
  socketUrl,
  logUrl,
  wayapayDispute,
  contentManagementUrl,
  notificationUrl,
  notificationAggUrl,
  fileResourseUrl,
  ussdTopUp,
  settlementUrl,
  commissionUrl,
  qrCodeUrl,
  referralUrl,
  walletV2,
  nipBvnUrl,
  fraudUrl,
  smAuthUrl,
  baseUrl,
  bankAuthUrl
};
const CLIENT_TYPE = 'ADMIN';
const CLIENT_ID = 'WAYABANK';

export const httpPost = async (url: any, postBody: any, isNotAuth: boolean, CT: string ='ADMIN') => {
  try {
    const res = await axios.post(
      `${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              'content-type': 'application/json',
              'CLIENT-TYPE': CT,
              'CLIENT-ID': CLIENT_ID,
            },
          }
        : {}
    );
    return res.data;
  } catch (error: any) {
    if (error?.response?.data.error === 'Internal Server Error') {
      return {
        status: false,
        message: error.response.data.error,
      };
    }
    return error.response?.data;
  }
};

export const httpPostUnreloaded = async (
  url: any,
  postBody: any,
  otherUrl: any,
  isNotAuth: boolean
) => {
  try {
    const res = await axios.post(
      `${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              'Content-Type': 'application/x-www-form-urlencoded',
              'CLIENT-TYPE': CLIENT_TYPE,
              'CLIENT-ID': CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

export const httpPostFormData = async (url: any, postBody: any, other: any, isNotAuth: boolean) => {
  
  try {
    const res = await axios.post(
      `${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              'Content-Type': 'multipart/form-data',
              'CLIENT-TYPE': CLIENT_TYPE,
              'CLIENT-ID': CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

export const httpGet = async (url: any, other: any, isNotAuth: boolean) => {
  
  try {
    const res = await axios.get(
      url,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              'CLIENT-TYPE': CLIENT_TYPE,
              'CLIENT-ID': CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error: any) {
    if (error?.response?.data?.message === 'Validation Errors') {
      Object.values(error?.response?.data?.data).map((item: any) =>
        Swal.fire('Oops!', item, 'error')
      );
      return error?.response?.data;;
    }
    return error?.response?.data;
  }
};

export const httpGetPdf = async (url: any, other: any, isNotAuth: boolean) => {
  try {
    const res = await axios.get(
      url,
      !isNotAuth
        ? {
          responseType: "blob",
            headers: {
              Authorization: `${localStorage.token}`,
              'Content-Type': 'application/pdf',
              'CLIENT-TYPE': CLIENT_TYPE,
              'CLIENT-ID': CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error: any) {
    if (error?.response?.data?.message === 'Validation Errors') {
      Object.values(error?.response?.data?.data).map((item: any) =>
        Swal.fire('Oops!', item, 'error')
      );
      return error?.response?.data;;
    }
    return error?.response?.data;
  }
};

export const httpPut = async (url: any, postBody: any, other: any, isNotAuth: boolean) => {
  try {
    const res = await axios.put(
      `${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              'CLIENT-TYPE': CLIENT_TYPE,
              'CLIENT-ID': CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error: any) {
    if (error.response.data.message === 'Validation Errors') {
      return {
        status: false,
        message: error.response?.data.data[0],
      };
    }
    return error.response?.data;
  }
};

export const httpPatch = async (url: any, postBody: any, other: any, isNotAuth: boolean) => {
  try {
    const res = await axios.patch(
      `${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              'CLIENT-TYPE': CLIENT_TYPE,
              'CLIENT-ID': CLIENT_ID,
            },
          }
        : {}
    );
    return res.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

export const httpDelete = async (url: any, data: any, other: any, isNotAuth: boolean) => {
  try {
    const res = await axios.delete(
      `${url}`,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              'CLIENT-TYPE': CLIENT_TYPE,
              'CLIENT-ID': CLIENT_ID,
            },
            data,
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error: any) {
    return error.response?.data;
  }
};
