import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';

type Props = {}

const GroupWithdrawal = (props: Props) => {
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='value of total Withdrawal (personal)' value="2500" percent="+36%" />
        <DashboardCards title='value of total Withdrawal (business)' value="1800" percent="-36%" />
        <DashboardCards title='count of total Withdrawal (personal)' value="350" percent="+36%" />
        <DashboardCards title='count of total withdrawal (business)' value="33493" percent="+36%" />
        <DashboardCards title='value of inter withdrawal (personal)' value="2500" percent="+36%" />
        <DashboardCards title='value of inter withdrawal (business)' value="1800" percent="-36%" />
        <DashboardCards title='total count of inter withdrawal (personal)' value="1800" percent="-36%" />
        <DashboardCards title='total count of inter withdrawal (business)' value="1800" percent="-36%" />
        <DashboardCards title='value of external withdrawal (personal)' value="2500" percent="+36%" />
        <DashboardCards title='value of external withdrawal (business)' value="1800" percent="-36%" />
        <DashboardCards title='total count of external withdrawal (personal)' value="350" percent="+36%" />
        <DashboardCards title='total count of external withdrawal (business)' value="33493" percent="+36%" />
        <DashboardCards title='value of bill  payment withdrawal (personal)' value="2500" percent="+36%" />
        <DashboardCards title='value of bill  payment withdrawal (business)' value="1800" percent="-36%" />
        <DashboardCards title='total count of billpayment withdrawal (personal)' value="1800" percent="-36%" />
        <DashboardCards title='total count of billpayment withdrawal (business)' value="1800" percent="-36%" />
      </div>

    </div>
  )
}

export default GroupWithdrawal
