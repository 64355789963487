import { TextField, Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';
import { Modal } from 'antd';
import { useState } from 'react';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};
const initData = {
  email: ""
}
const AssignCards = (props: Props) => {
  const [showAssign, setShowAssign] = useState(false);
  const [data, setData] = useState(initData);
  return (
    <div className='h-full bg-gray-accent'>
      <Modal 
        title={`Assign ATM Card`} 
        open={showAssign} onOk={() => setShowAssign(false)}
        onCancel={()=> setShowAssign(false)}
        footer={null}
        // footer={[
        //   <Button key="back" onClick={handleSubmit}>
        //     Register
        //   </Button>
        // ]}
      >
        <div>
            <TextField
              label="Email"
              name='email'
              placeholder='Enter otp sent to your email address'
              value={data?.email}
              onChange={(e) => setData({...data, email: e.target.value})}
              color='warning'
              fullWidth
            />
        </div>
      </Modal>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>
            Hi {localStorage.name} !
        </Typography>
        <div className='flex gap-5'>
          < div
            onClick={() => setShowAssign(true)}
            className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'>
          <AddCircleIcon className='mr-2 text-xl' />Assign Card</div>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='Total number of Assigned Cards' value="2500" 
        percent="+36%"
        isLoading
         />
      </div>

      {/* <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      /> */}
    </div>
  )
}

export default AssignCards
