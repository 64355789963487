import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import StatsCard from '../../components/cards/statsCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TuneIcon from '@mui/icons-material/Tune';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

const Chats = (props: Props) => {
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div className='flex gap-5'>
          <button
            className='flex items-center justify-center transition-all duration-300 px-3 py-1 float-right hover:text-white border-[#FF7D58] border-[1px] hover:bg-[#FF7D58] bg-transparent text-[#FF7D58] rounded-2xl'
          >
            <TuneIcon className='mr-2' />
            filter
          </button>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='total interactions' value="2500" percent="+36%" />
        <DashboardCards title='count of users call made' value="1800" percent="-36%" />
        <DashboardCards title='total size tracking of user calls' value="350" percent="+36%" />
        <DashboardCards title='counts of chat transactions initiated' value="33493" percent="+36%" />
        <DashboardCards title='amount of chat transactions initiated' value="2500" percent="+36%" />
        <DashboardCards title='count of active users transactions and analysis' value="1800" percent="-36%" />
        <DashboardCards title='count of unique chats made' value="350" percent="+36%" />
        <DashboardCards title='count of total chats recorded per user' value="33493" percent="+36%" />
        <DashboardCards title='count of users voice calls' value="33493" percent="+36%" />
        <DashboardCards title='feedback rating analysis on voice interactions' value="33493" percent="+36%" />
        <DashboardCards title='count of users group calls initiated' value="33493" percent="+36%" />
        <DashboardCards title='size of total group chat initiated' value="33493" percent="+36%" />
        <DashboardCards title='size of deleted group chats and audit trail monitoring' value="350" percent="+36%" />
        <DashboardCards title='unique users call logs' value="350" percent="+36%" />
      </div>
      <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="70%"
        height="300px"
        legendToggle
      />

    </div>
  )
}

export default Chats
