import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { StyledEngineProvider } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SigninOptionsPage from "./views/auth-pages/signinOptions";
import Login from "./views/auth-pages/login";
import ResetPassword from "./views/auth-pages/resetPassword";
import CheckMail from "./views/auth-pages/checkMail";
import ForgetPassword from "./views/auth-pages/forgetPassword";
import SelectDashboard from "./views/auth-pages/selectDashboard";
import SuccessPage from "./views/auth-pages/successPage";
import MainLayout from "./layouts/mainLayout";
import AnonymousLayout from "./layouts/anonymousLayout";
import NotFound from "./views/auth-pages/not-found";
import User from "./views/dashboard-pages/user";
import AddUser from "./views/dashboard-pages/addUser";
import GenerateReport from "./views/dashboard-pages/generateReport";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UserReport from "./views/dashboard-pages/usersReport";
import Deposit from "./views/dashboard-pages/deposit";
import NIP from "./views/dashboard-pages/nip";
import Transfer from "./views/dashboard-pages/transfer";
import Withdrawal from "./views/dashboard-pages/withdrawal";
import BillPayment from "./views/dashboard-pages/billpayment";
import Referral from "./views/dashboard-pages/referral";
import CommissionPayment from "./views/dashboard-pages/commissionPayment";
import Revenue from "./views/dashboard-pages/revenue";
import AtmCard from "./views/dashboard-pages/atmCard";
import PaymentRequest from "./views/dashboard-pages/paymentRequest";
import Invoice from "./views/dashboard-pages/invoice";
import ScanAndPay from "./views/dashboard-pages/scanAndPay";
import Ussd from "./views/dashboard-pages/ussd";
import CardPaymentGateway from "./views/dashboard-pages/cardPaymentGateway";
import PosTerminal from "./views/dashboard-pages/posTerminal";
import AnonymousWayaQuick from "./layouts/anonymousWayaQuick";
import WayaquickUser from "./views/wayaquick-pages/user";
import WayaQuickCommissionPayment from "./views/wayaquick-pages/commissionPayment";
import WayaQuickRevenue from "./views/wayaquick-pages/revenue";
import WayaQuickInvoice from "./views/wayaquick-pages/invoice";
import PaymentPageTerminal from "./views/wayaquick-pages/payment-page-terminal";
import PaymentPage from "./views/wayaquick-pages/paymentPage";
import KycVerification from "./views/wayaquick-pages/kycVerification";
import Sms from "./views/wayaquick-pages/sms";
import AccountVerificationAndLogin from "./views/wayaquick-pages/accountVerificationAndLogin";
import AnonymousWayaGram from "./layouts/anonymousWayaGram";
import WayagramUser from "./views/wayagram-pages/user";
import WayaGramCommissionPayment from "./views/wayagram-pages/commissionPayment";
import WayaGramRevenue from "./views/wayagram-pages/revenue";
import Product from "./views/wayagram-pages/product";
import Share from "./views/wayagram-pages/share";
import Comment from "./views/wayagram-pages/comment";
import Hashtags from "./views/wayagram-pages/hashtag";
import Synchronization from "./views/wayagram-pages/synchronization";
import Follow from "./views/wayagram-pages/follow";
import Posts from "./views/wayagram-pages/posts";
import Media from "./views/wayagram-pages/media";
import Image from "./views/wayagram-pages/images";
import Chats from "./views/wayagram-pages/chats";
import WayaGramKycVerification from "./views/wayagram-pages/kycVerification";
import WayaGramSms from "./views/wayagram-pages/sms";
import WayaGramAccountVerificationAndLogin from "./views/wayagram-pages/accountVerificationAndLogin";
import AnonymousWayaGroup from "./layouts/anonymousWayaGroup";
import GroupUser from "./views/wayagroup-pages/user";
import GroupDeposit from "./views/wayagroup-pages/deposit";
import GroupNIP from "./views/wayagroup-pages/nip";
import GroupTransfer from "./views/wayagroup-pages/transfer";
import GroupWithdrawal from "./views/wayagroup-pages/withdrawal";
import GroupReferral from "./views/wayagroup-pages/referral";
import GroupCommissionPayment from "./views/wayagroup-pages/commissionPayment";
import GroupRevenue from "./views/wayagroup-pages/revenue";
import GroupAtmCard from "./views/wayagroup-pages/atmCard";
import GroupPosTerminal from "./views/wayagroup-pages/posTerminal";
import GroupKycVerification from "./views/wayagroup-pages/kycVerification";
import GroupSms from "./views/wayagroup-pages/sms";
import GroupAccountVerificationAndLogin from "./views/wayagroup-pages/accountVerificationAndLogin";
import { AuthProvider } from "./context/AuthProvider";
import CardLogin from "./views/auth-pages/cardLogin";
import CardLayout from "./layouts/cardsLayout";
import AssignCards from "./views/dashboard-pages/assignCard";

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "auth/:route",
        element: <Login />,
      },
      {
        path: "auth/card/login",
        element: <CardLogin />,
      },
      {
        path: "/forgot_password",
        element: <ForgetPassword />,
      },
      {
        path: "/reset_password",
        element: <ResetPassword />,
      },
      {
        path: "/check_mail",
        element: <CheckMail />,
      },
      {
        path: "/password_changed",
        element: <SuccessPage />,
      },
      {
        path: "/user/add_user",
        element: <AddUser />,
      },
    ],
  },
  {
    element: <CardLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/assign/atm",
        element: <AssignCards />,
      },
      {
        path: "/assign/qr",
        element: <AssignCards />,
      },
    ],
  },
  {
    element: <AnonymousLayout />,
    children: [
      {
        path: "/user",
        element: <User />,
      },
      {
        path: "/user/generate_report",
        element: <GenerateReport />,
      },
      {
        path: "/user/report",
        element: <UserReport />,
      },
      {
        path: "/deposit",
        element: <Deposit />,
      },
      {
        path: "/nip",
        element: <NIP />,
      },
      {
        path: "/transfer",
        element: <Transfer />,
      },
      {
        path: "/withdrawal",
        element: <Withdrawal />,
      },
      {
        path: "/bill_payment",
        element: <BillPayment />,
      },
      {
        path: "/referral",
        element: <Referral />,
      },
      {
        path: "/commission_payment",
        element: <CommissionPayment />,
      },
      {
        path: "/revenue",
        element: <Revenue />,
      },
      {
        path: "/atm_card",
        element: <AtmCard />,
      },
      {
        path: "/payment_request",
        element: <PaymentRequest />,
      },
      {
        path: "/invoice",
        element: <Invoice />,
      },
      {
        path: "/scan_and_pay",
        element: <ScanAndPay />,
      },
      {
        path: "/ussd",
        element: <Ussd />,
      },
      {
        path: "/card_payment_gateway",
        element: <CardPaymentGateway />,
      },
      {
        path: "/pos_terminal",
        element: <PosTerminal />,
      },
    ],
  },
  {
    element: <AnonymousWayaQuick />,
    children: [
      {
        path: "/wayaquick/user",
        element: <WayaquickUser />,
      },
      {
        path: "/wayaquick/commission_payment",
        element: <WayaQuickCommissionPayment />,
      },
      {
        path: "/wayaquick/revenue",
        element: <WayaQuickRevenue />,
      },
      {
        path: "/wayaquick/invoice",
        element: <WayaQuickInvoice />,
      },
      {
        path: "/wayaquick/payment_page_terminal",
        element: <PaymentPageTerminal />,
      },
      {
        path: "/wayaquick/payment_page",
        element: <PaymentPage />,
      },
      {
        path: "/wayaquick/kyc_verification",
        element: <KycVerification />,
      },
      {
        path: "/wayaquick/sms",
        element: <Sms />,
      },
      {
        path: "/wayaquick/account_creation_and_login",
        element: <AccountVerificationAndLogin />,
      },
      {
        path: "/wayaquick/profiling",
        element: <Sms />,
      },
    ],
  },
  {
    element: <AnonymousWayaGram />,
    children: [
      {
        path: "/wayagram/user",
        element: <WayagramUser />,
      },
      {
        path: "/wayagram/commission_payment",
        element: <WayaGramCommissionPayment />,
      },
      {
        path: "/wayagram/revenue",
        element: <WayaGramRevenue />,
      },
      {
        path: "/wayagram/product",
        element: <Product />,
      },
      {
        path: "/wayagram/share",
        element: <Share />,
      },
      {
        path: "/wayagram/comment",
        element: <Comment />,
      },
      {
        path: "/wayagram/hashtag",
        element: <Hashtags />,
      },
      {
        path: "/wayagram/synchronization",
        element: <Synchronization />,
      },
      {
        path: "/wayagram/follow",
        element: <Follow />,
      },
      {
        path: "/wayagram/post",
        element: <Posts />,
      },
      {
        path: "/wayagram/media",
        element: <Media />,
      },
      {
        path: "/wayagram/image",
        element: <Image />,
      },
      {
        path: "/wayagram/chat",
        element: <Chats />,
      },
      {
        path: "/wayagram/kyc_verification",
        element: <WayaGramKycVerification />,
      },
      {
        path: "/wayagram/sms",
        element: <WayaGramSms />,
      },
      {
        path: "/wayagram/account_creation_and_login",
        element: <WayaGramAccountVerificationAndLogin />,
      },
    ],
  },
  {
    element: <AnonymousWayaGroup />,
    children: [
      {
        path: "/wayagroup/user",
        element: <GroupUser />,
      },
      {
        path: "/wayagroup/deposit",
        element: <GroupDeposit />,
      },
      {
        path: "/wayagroup/nip",
        element: <GroupNIP />,
      },
      {
        path: "/wayagroup/transfer",
        element: <GroupTransfer />,
      },
      {
        path: "/wayagroup/withdrawal",
        element: <GroupWithdrawal />,
      },
      {
        path: "/wayagroup/referral",
        element: <GroupReferral />,
      },
      {
        path: "/wayagroup/commission_payment",
        element: <GroupCommissionPayment />,
      },
      {
        path: "/wayagroup/revenue",
        element: <GroupRevenue />,
      },
      {
        path: "/wayagroup/atm_card",
        element: <GroupAtmCard />,
      },
      {
        path: "/wayagroup/pos_terminal",
        element: <GroupPosTerminal />,
      },
      {
        path: "/wayagroup/kyc_verification",
        element: <GroupKycVerification />,
      },
      {
        path: "/wayagroup/sms",
        element: <GroupSms />,
      },
      {
        path: "/wayagroup/account_creation_and_login",
        element: <GroupAccountVerificationAndLogin />,
      },
      {
        path: "/wayagroup/profiling",
        element: <GroupAccountVerificationAndLogin />,
      },
    ],
  },
  {
    path: "/",
    element: <SelectDashboard />,
  },
  {
    path: "/select_options",
    element: <SigninOptionsPage />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StyledEngineProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </AuthProvider>
    </LocalizationProvider>
  </StyledEngineProvider>
);
