import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';
import { customBaseUrl, httpGet } from '../../api/https';
import { useEffect, useState } from 'react';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

interface Posstat {
  activeTerminals: number
  damagedTerminals: number
  deactivatedTerminals: number
  faultyTerminals:number
  suspendedTerminals: number
  totalTerminals: number
}
interface Posstat2 {
  approvedRequest:number
    pendingRequest:number
    rejectedRequest:number
    totalRequest: number
}
interface Posstat4 {
  gross_revenue: number
    net_revenue: number
}

const PosTerminal = (props: Props) => {

  const [posStat, setPosStat] = useState<Posstat>({
    activeTerminals:0,
damagedTerminals: 0,
deactivatedTerminals: 0,
faultyTerminals:0,
suspendedTerminals:0,
totalTerminals:0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [posStat2, setPosStat2] = useState<Posstat2>({
    approvedRequest:0,
    pendingRequest:0,
    rejectedRequest:0,
    totalRequest:0
  });
  const [isLoading2, setIsLoading2] = useState(true);
  const [posStat4, setPosStat4] = useState<Posstat4>({
    gross_revenue:0,
    net_revenue:0
  });
  const [isLoading4, setIsLoading4] = useState(true);

  const fetchTrend = async () => {
    const res1 = await httpGet(`${customBaseUrl.baseUrl}/pos/reports/terminals/admingetallstats`,'',false);
    if (res1?.respCode === 0) {
    setPosStat(res1?.respBody);
    setIsLoading(false);
    }
    const res2 = await httpGet(`${customBaseUrl.baseUrl}/pos/reports/terminals/terminalRequestStats`,'',false);
    if (res2?.respCode === 0) {
    setPosStat2(res2?.respBody);
    setIsLoading2(false);
    }
    // const res3 = await httpGet(`${customBaseUrl.baseUrl}/pos/reports/transactions/metric/data`,'',false);
    // if (res1?.respCode === 0) {
    // setPosStat2(res2?.respBody);
    // setIsLoading2(false);
    // }
    const res4 = await httpGet(`${customBaseUrl.baseUrl}/pos/reports/settlement/getTransactionStats`,'',false);
    if (res4?.respCode === 0) {
    setPosStat4(res4?.respBody);
    setIsLoading4(false);
    }
  };

  useEffect(() => {
    fetchTrend();
  }, []);

  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title={`Gross revenue`} value={posStat4?.gross_revenue.toString() ?? '0'}
          isLoading={isLoading4}
          percent="-36%"/>
        <DashboardCards title={`Net revenue`} value={posStat4?.net_revenue.toString() ?? '0'}
          isLoading={isLoading4}
          percent="-36%"/>
        </div>
      <div className='grid grid-cols-4 gap-5 mt-4'>
        <DashboardCards title={`Total pos terminals`} value={posStat?.totalTerminals.toString() ?? '0'}
          isLoading={isLoading}
          percent="-36%"/>
        <DashboardCards title={`Total active pos terminals`} value={posStat?.activeTerminals.toString() ?? '0'}
          isLoading={isLoading}
          percent="-36%"/>
        <DashboardCards title={`Total deactivated pos terminals`} value={posStat?.deactivatedTerminals.toString() ?? '0'}
          isLoading={isLoading}
          percent="-36%"/>
          <DashboardCards title={`Total suspended pos terminals`} value={posStat?.suspendedTerminals.toString() ?? '0'}
          isLoading={isLoading}
          percent="-36%"/>
          <DashboardCards title={`Total damaged pos terminals`} value={posStat?.damagedTerminals.toString() ?? '0'}
          isLoading={isLoading}
          percent="-36%"/>
      </div>
      <div className='grid grid-cols-4 gap-5 mt-6'>
        <DashboardCards title={`Total pos terminals requested`} value={posStat2?.totalRequest.toString() ?? '0'}
          isLoading={isLoading2}
          percent="-36%"/>
        <DashboardCards title={`Total pos terminals approved`} value={posStat2?.approvedRequest.toString() ?? '0'}
          isLoading={isLoading2}
          percent="-36%"/>
        <DashboardCards title={`Total pos terminals rejected`} value={posStat2?.rejectedRequest.toString() ?? '0'}
          isLoading={isLoading2}
          percent="-36%"/>
          <DashboardCards title={`Total pos terminals pending`} value={posStat2?.pendingRequest.toString() ?? '0'}
          isLoading={isLoading2}
          percent="-36%"/>
          
      </div>

      {/* <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      /> */}
    </div>
  )
}

export default PosTerminal
