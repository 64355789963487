import { useNavigate, useParams } from "react-router-dom";
import LoginForm from "../../components/forms/loginForm";
import { FormikValues } from "formik";
import useAuth from "../../hooks/useAuth";
import { customBaseUrl, httpPost } from "../../api/https";
import { useState } from "react";
import { TextField } from "@mui/material";
import { Button, Modal } from "antd"
import Swal from "sweetalert2";

function CardLogin() {
  const { route } = useParams();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [loginData, setLoginData] = useState<any>({});

  const handleLogin = async ({ loginId, password }: FormikValues) => {
    try {
      const response = await httpPost(`${customBaseUrl.bankAuthUrl}/api/v1/auth/login`,
        {
            emailOrPhoneNumber: loginId,
            otp,
            password,
          },
          true,
        );
      const { id, token, roles, profileSubscription, user } = response?.data;
      localStorage.setItem("token", token);
      localStorage.setItem("userId", user?.id);
      localStorage.setItem("profileId", profileSubscription?.profileId);
      localStorage.setItem("roles", roles);
      localStorage.setItem("user", response);
      localStorage.setItem("name", user?.firstName + ' '+ user?.lastName);
      // const user = response;
      login({ user, token });
      if (response.status) {
      navigate("/assign/atm");
    }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetOtp = async ({ email, password }: FormikValues) => {
    try {
      const response = await httpPost(`${customBaseUrl.baseUrl}/otp/report/send-otp`,{
        email
      }, true);
      if (response.success === true) {
        setShowOtp(true);
        setLoginData({email, password});
      } else {
        Swal.fire('Oops!', response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center bg-gray-100 justify-center h-screen font-lato">
      <Modal 
        title="Comfirm login OTP" 
        open={showOtp} onOk={handleLogin} 
        onCancel={()=> setShowOtp(false)}
        footer={[
          <Button key="back" onClick={handleLogin}>
            Login
          </Button>
        ]}
      >
    <div className="p-5">
      <div className="mb-3">
        </div>
        <TextField
                label="OTP"
                name='otp'
                placeholder='Enter otp sent to your email address'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                color='warning'
                fullWidth
              />
        {/* <Input
              className="rounded"
              type="text"
              id="otp"
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
        /> */}
        </div>
      </Modal>
      <LoginForm
        title={`Login to assign cards`}
        handleSubmit={handleLogin}
        card
      />
    </div>
  );
}

export default CardLogin;
