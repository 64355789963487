import { InputAdornment, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from "yup";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import Back from '../navigation/back';

type Props = {
  title: string
  handleSubmit: (values: FormikValues) => void;
  card: boolean
}

const LoginForm = ({ title, handleSubmit, card }: Props) => {

  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    email: "",
    password: "",
    loginId: null
  }

  const validateLoginSchema = yup.object().shape({
    email: yup.string().email(),
    password: yup.string().required("Required"),
    // loginId: yup.number()
  })

  return (
    <div className='px-5 border-gray-200 py-9 border-[1px] bg-white rounded-md h-fit w-[400px]'>
      <Back />
      <div className='flex text-center flex-col mb-8 items-center justify-center'>
        <Typography variant='h5' className='font-bold'>{title}</Typography>
        <Typography variant='subtitle2' color="gray" className='mt-1'>Enter your credentials to access your dashboard</Typography>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validateLoginSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <div className='flex flex-col items-center justify-center gap-4'>
              {card ? <TextField
                label="Login Id"
                name='loginId'
                placeholder="Enter LoginId"
                value={values.loginId}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.loginId && !!errors.loginId}
                helperText={!!touched.loginId && errors.loginId}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              /> :
              <TextField
                label="Email address"
                name='email'
                placeholder={card ? 'Enter LoginId' : 'Enter your work email address'}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />}
              <TextField
                label="Password"
                name='password'
                type={showPassword ? "text" : "password"}
                placeholder='Enter password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.password && !!errors.password}
                helperText={!!touched.password && errors.password}
                InputProps={{
                  className: "cursor-pointer",
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? <VisibilityOffIcon onClick={() => setShowPassword(false)} /> : <VisibilityIcon onClick={() => setShowPassword(true)} />}
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
              <div className='flex items-center w-full justify-end '>
                <Link to="/forgot_password" className='text-[#FF7D58] mb-2 mt-1'>Forget Password?</Link>
              </div>
              <button
                type='submit'
                className='transition-all duration-300 col-span-2 py-3 w-full text-white hover:border-[#FF7D58] border-[1px] border-transparent bg-[#FF7D58] hover:bg-transparent hover:text-[#FF7D58] rounded-md'
              >
                Login to Dashboard
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm;
