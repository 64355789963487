import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/layout/navbar";
import Sidebar from "../components/layout/sidebar";
import useAuth from "../hooks/useAuth";

type Props = {};

const AnonymousLayout = (props: Props) => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate("/select_options");
    }
  }, [auth]);

  return (
    <>
      <Navbar />
      <div className="flex bg-gray-accent">
        <Sidebar isCard={false}/>
        <div className="w-full pb-14 px-14">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AnonymousLayout;
